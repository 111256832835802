(function($) {

    function headingAlignment() {
        var ancestor = $('.ai-heading__ancestor');
        var ancestorWidth = ancestor.width();
        ancestor.css({
            left: - (ancestorWidth + (ancestorWidth / 3.5)) + 'px',
        })
    }

    $(document).ready(function() {
        $('.ai-menu-caller').on('click', function() {
            $('.ai-menu--mobile').toggleClass('ai-menu--expanded');
        });

        $('.ai-wrapper, .ai-menu-closer').on('click touchstart', function () {
            $('.ai-menu--mobile').removeClass('ai-menu--expanded')
        });

        $(".owl-carousel").owlCarousel({
            items: 1,
            autoplayTimeout: 10000,
            autoplay: true,
            loop: true,
            animateOut: 'fadeOut',
            animateIn: 'fadeIn'
        });

        headingAlignment();
        $('textarea').textareaAutoSize();

        var initial_text = '';
        $('.ai-contact__form').on('submit', function() {
            initial_text = $(this).find('[type="submit"]').text();
            $(this).find('[type="submit"]').text('Loading...');
        });


        $(document).on('af_complete', function() {
            var that = this;
            $(that).find('[type="submit"]').text('Done!');
            setTimeout(function(){
                $(that).find('[type="submit"]').text(initial_text);
            }, 2500)
        });

        LGoptions = {
            selector: '[data-toggle="lightbox"]',
            download: false,
            controls: $(window).width() > 980,
            scale: 1
        }

        var el = document.getElementById('lightgallery');

        lightGallery(el, LGoptions);

        $(document).on('pdopage_load', function() {
            window.lgData[el.getAttribute('lg-uid')].destroy(true)
            lightGallery(el, LGoptions);
        });

    });

    $(window).on('resize', headingAlignment);
})(jQuery);